import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import {useLocalStorage} from '@rehooks/local-storage';
import {LocalStorageKeys, RoleId} from 'common/types';
import {ButtonPurple} from 'components/Buttons';
import {IconClose} from 'components/Icons';
import {isToday} from 'date-fns';
import {useCurrentUser} from 'hooks/user';
import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import RouterLink from 'screens/common/RouterLink';
import {rieltor} from 'styles/theme';
import Typo from '../../../components/Typo';

const useForceVerificationStyles = makeStyles((theme) => ({
  popupWrapper: {
    '& .MuiDialog-paper': {
      maxWidth: '550px !important',
    },
    '& .MuiDialogContent-root': {
      padding: '8px 30px',
    },
  },
}));

const ForceVerificationAlert = () => {
  const classes = useForceVerificationStyles();
  const user = useCurrentUser();
  const [open, setOpen] = useState(false);

  const [
    lastOpenDate,
    setLastOpenDate
  ] = useLocalStorage<string|null>(LocalStorageKeys.ForceVerificationAlert, null);

  let today = false;
  if (lastOpenDate !== null) {
    today = isToday(Date.parse(lastOpenDate));
  }

  useEffect(() => {
    if (!today && user && !user.verificated && (user.roleId === RoleId.Owner || user.roleId === RoleId.Realtor)) {
      setOpen(true);
      setLastOpenDate((new Date()).toString());
    }
  }, [today]);

  if (user === null) {
    return null;
  }

  return (
    <Dialog
      className={classes.popupWrapper}
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle style={{padding: '8px 16px'}}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <div>&nbsp;</div>

          <IconClose
            style={{cursor: 'pointer', color: rieltor.gray4}}
            onClick={() => setOpen(false)}
          />
        </Box>
      </DialogTitle>

      <DialogContent>
        <div>
          <Typo variant={'title0'}>
            <FormattedMessage id={'dashboard.add_verification'}/>
          </Typo>
        </div>

        <p style={{margin: '30px 0', lineHeight: '28px', fontFamily: rieltor.fontFamily, fontSize: '16px'}}>
          <FormattedMessage id={'dashboard.add_verification_description'}/>
        </p>
      </DialogContent>

      <DialogActions style={{justifyContent: 'center', padding: '0 16px 20px 16px'}}>
        <ButtonPurple
          // @ts-ignore
          component={RouterLink}
          to={`/profile/summary`}
        >
          Верифікуватись
        </ButtonPurple>
      </DialogActions>
    </Dialog>
  );
};

export default ForceVerificationAlert;
