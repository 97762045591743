import {Box, Button, Link as MuiLink, makeStyles, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {useLocalStorage} from '@rehooks/local-storage';
import {
  AlertType, CityId,
  LocalStorageKeys, OffersManagementField,
  QueryParam, RegionId,
  RoleId,
  UserCustomAlert, UserInfo, UserLevelStatus,
} from 'common/types';
import {makeTrackable} from 'modules/ga';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {FormattedMessage, FormattedMessage as FM, useIntl} from 'react-intl';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {CurrentUser} from 'screens/common/CurrentUser';
import Price from 'screens/common/Price';
import RouterLink from 'screens/common/RouterLink';
import {
  createProfileAgentsManagementUrl,
  parseLocationSearch,
} from 'utils/navigation';
import DiiaAlert from "./DiiaAlert";
import BankidAlert from "./BankidAlert";
import OlxMigrationAlert from "../../../components/OlxMigrationAlert";
import OlxDialog from '../../OlxMigrationScreen/OlxDialog';
import ReferralAlert from 'screens/ReferralsScreen/ReferralAlert';
import {useSelector} from "react-redux";
import {
  newOwnerBonusSelector, newOwnerBonusStandardDateSelector,
  newRieltorBonusSelector, newRieltorBonusStandardAmountSelector,
  referralProgramRegisterSelector
} from "../../../store/global/selectors";
import cx from "classnames";
import {UsersAPI} from '../../../services/UsersAPI';
import {getNewUserBonus, getUserTariff} from "../../../utils";
import NewStatusSoonAlert from "./NewStatusSoonAlert";
import VerificationSuccessAlert from "./VerificationSuccessAlert";
import RieltorManifest from "../../RieltorManifestScreen/RieltorManifest";
import RieltorGiftAlert from './RieltorGiftAlert';
import ForceVerificationAlert
  from '../../MainLayout/Alerts/ForceVerificationAlert';
import {rieltor} from '../../../styles/theme';
import {ButtonWhite} from '../../../components/Buttons';


// Same const on backend
export const INSUFFICIENT_FUNDS_ALERT_DAYS_MAX = 3;
const ALERT_NEW_RIELTOR_BONUS_KO = "200";
const ALERT_NEW_RIELTOR_BONUS_OLD_USERS_KO = "210";

const ALERT_NEW_OWNER_BONUS_KO = "220";
const ALERT_NEW_OWNER_BONUS_OLD_USERS_KO = "221";

const ALERT_NEW_RIELTOR_BONUS_TOP = "300";
const ALERT_NEW_RIELTOR_BONUS_OLD_USERS_TOP = "301";

const ALERT_RIELTOR_MANIFEST = "290";


const NEW_USER_BONUS_ALERTS_KO: any = {[RoleId.Realtor]: [ALERT_NEW_RIELTOR_BONUS_KO, ALERT_NEW_RIELTOR_BONUS_OLD_USERS_KO], [RoleId.Owner]: [ALERT_NEW_OWNER_BONUS_KO, ALERT_NEW_OWNER_BONUS_OLD_USERS_KO]};
const NEW_USER_BONUS_ALERTS_TOP: any = {[RoleId.Realtor] : [ALERT_NEW_RIELTOR_BONUS_TOP, ALERT_NEW_RIELTOR_BONUS_OLD_USERS_TOP]};


const TrackableButton = makeTrackable(Button);

const TrackableMuiLink = makeTrackable(MuiLink);

const useStyles = makeStyles((theme) => ({
  userAlertText: {
    fontFamily: rieltor.fontFamily,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    '& h2:first-child': {
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '30px',
      margin: '0px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      'grid-template-columns': 'auto auto',
      'justify-content': 'space-between',
    },
    '& img': {
      'max-width': '100%',
      height: 'auto !important',
      float: 'none !important' as any,
      [theme.breakpoints.down('xs')]: {
        margin: '10px auto',
        display: 'block',
      },
    },
  },
  userAlert: {
    color: 'white',
    alignItems: 'baseline',
    marginBottom: theme.spacing(1),
    padding: '20px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
    '& .MuiAlert-icon': {
      color: 'white',
    },
    '& a': {
      color: '#FFF',
      '&:hover': {
        color: '#FFF',
      },
    },
    '&:first-child': {
      marginTop: theme.spacing(2),
    },
    '&:last-child': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiAlert-message': {
      flex: '1',
      padding: 0,
    },
    '& .MuiAlert-action': {
      position: 'absolute',
      top: '16px',
      right: '16px',
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1.5rem',
    }
  },
  userAlertWarning: {
    color: '#000000',
    '& .MuiAlert-icon': {
      color: '#ff9800',
    },
  },
  alert: {
    alignItems: 'baseline',
    marginBottom: theme.spacing(1),
    '&:first-child': {
      marginTop: theme.spacing(2),
    },
    '&:last-child': {
      marginBottom: '8px',
    },
  },
  nonVerifiedAlert: {
    backgroundColor: '#F7ADA7',
    // color: 'white',
    '& svg': {
      fill: 'red'
    }
  },
  alertParagraph: {
    marginBottom: theme.spacing(1),
  },
  highlighted: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  bannerBox: {
    background: '#8811FF',
    padding: '21px 51px 21px 25px',
    borderRadius: 3,
    marginTop: 18,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: '18px 19px 17px',
    },
  },
  alertPartner: {
    borderRadius: 4,
    background: '#8811FF',
    display: 'grid',
    // gridTemplateColumns: '25% 38%',
    gridTemplateColumns: 'auto calc(474px + 8%)',
    paddingRight: '8%',
    justifyContent: 'space-between',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      paddingRight: 0,
    },
    [theme.breakpoints.up('md')]: {
      '&.-new-user-bonus-alert': {
        paddingRight: '2%',
        '& $alertPartnerImgWr img': {
          width: 400,
          height: 242
        }
      }
    }
  },
  alertPartnerClose: {
    position: 'absolute',
    top: 11,
    right: 15,
    width: 26,
    height: 26,
    zIndex: 1,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    [theme.breakpoints.down('xs')]: {
      top: 0,
      right: 0,
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    '& svg': {
      width: 16,
      height: 16
    }
  },
  alertPartnerInfo: {
    padding: '20px',
    [theme.breakpoints.down('xs')]: {
      padding: '18px 19px 13px',
    },
  },
  alertPartnerTitle: {
    color: '#FFF',
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: '30px',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      lineHeight: '20px',
      marginBottom: 20,
    },
  },
  alertPartnerSubTitle: {
    color: '#FFF',
    fontSize: 15,
    fontWeight: 700,
    lineHeight: '22px',
    marginBottom: 9,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 13,
    },
  },
  alertPartnerText: {
    color: '#FFF',
    // fontSize: 12,
    lineHeight: '20px',
    marginBottom: 15,
    '& strong': {
      color: '#FFCB2B',
      fontWeight: 700,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 30,
    },
    '& span': {
      color: '#FFCB2B',
      fontWeight: 800,
    },
  },
  alertPartnerLink: {
    alignItems: 'center',
    borderRadius: 3,
    border: '1px solid #FFF',
    padding: '5px 28px',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
    '& svg': {
      marginLeft: 6,
      width: 8
    }
  },
  alertPartnerImgWr: {
    position: 'relative',
    marginLeft: 'auto'
  },
  alertPartnerImg: {
    height: '100%',
    display: 'block',
    objectFit: 'cover',
    objectPosition: 'left',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  alertPartnerImgMob: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '344px',
      margin: 'auto'
    },
  }

}));

export interface UserAlertsProps extends RouteComponentProps {}

const UserAlerts: FunctionComponent<UserAlertsProps> = ({
  location,
}: UserAlertsProps) => {
  const classes = useStyles();

  const params = useMemo(
    () => parseLocationSearch(location.search),
    [location]
  );

  const isEnabled =
    (location.pathname.startsWith('/offers/management') ||
    location.pathname.startsWith('/deals') ||
    location.pathname.startsWith('/dashboard') ||
    location.pathname.startsWith('/rieltor-manifest') ||
    location.pathname.startsWith('/profile/stats'));

  const isUserAlertsEnabled =  (location.pathname.startsWith('/offers/management') ||
    location.pathname.startsWith('/deals') ||
    location.pathname.startsWith('/dashboard') ||
    location.pathname.startsWith('/profile/stats'));

  const usersAPI = useMemo(() => new UsersAPI(), []);
  const isEnabledForManagement = location.pathname.startsWith('/offers/management');
  const isEnabledForProfile = location.pathname.startsWith('/profile/summary') && params[QueryParam.AlertType];

  const {formatMessage: fm, messages} = useIntl();
  const intl = useIntl();

  const [isOlxDialogOpen, setIsOlxDialogOpen] = useState(false);
  const [isNoVerifiedShowed, setIsNoVerifiedShowed] = useState(true);
  const closeOlxDialog = () => {
    setIsOlxDialogOpen(false);
  };

  const newRieltorBonuses = useSelector(newRieltorBonusSelector);
  const newOwnerBonuses = useSelector(newOwnerBonusSelector);
  // const newRieltorBonusStandardDate = useSelector(newRieltorBonusStandardAmountSelector);
  // const newOwnerBonusStandardDate = useSelector(newOwnerBonusStandardDateSelector);

  const referralProgramRegisterEnabled = false; // ; useSelector(referralProgramRegisterSelector);

  const [isReferralBannerClosed, setIsReferralBannerClosed] =
    useLocalStorage<boolean>(LocalStorageKeys.ReferralBanner, false);

  const [isNewUserAlertClosed, setNewUserAlertClosed] = useLocalStorage<boolean>(LocalStorageKeys.NewUserAlertClosed, false);

  const openOlxDiloag = () => {
    setIsOlxDialogOpen(true);
  };

  const [isNoPhotoAlertClosed, setIsNoPhotoAlertClosed] =
    useLocalStorage<boolean>(LocalStorageKeys.NoPhotoAlertClosed, false);

  const [isUserAlertVisible, setisUserAlertVisible] = useLocalStorage<{
    [k: string]: boolean;
  }>(LocalStorageKeys.UserAlert, {});

  const [isLevelStatusAlertVisible, setIsLevelStatusAlertVisible] = useLocalStorage<{
    [k: string]: boolean;
  }>(LocalStorageKeys.LevelStatusAlert, {});

  const handleCloseBanner = useCallback(() => {
    setIsReferralBannerClosed(true);
  }, [setIsReferralBannerClosed]);

  const handleNewUserAlertClose = useCallback(() => {
    setNewUserAlertClosed(true);
  }, [setNewUserAlertClosed]);

  const handleUserAlertClose = useCallback(
    (id: string) => {
      setisUserAlertVisible({...isUserAlertVisible, ...{[id]: false}});
      usersAPI.updateAlertStatus({id, isManuallyClosed: true});
    },
    [setisUserAlertVisible]
  );

  const [isOlxAlertVisible, setisOlxAlertVisible] = useLocalStorage<boolean>(LocalStorageKeys.OlxAlert, false);

  const handleOlxAlertClose = useCallback((showDialog?: boolean) => {
    setisOlxAlertVisible(!isOlxAlertVisible);
    if (showDialog) {
      openOlxDiloag();
    }
  }, [setisOlxAlertVisible]);

  const handleNoPhotoAlertClose = useCallback(() => {
    setIsNoPhotoAlertClosed(true);
  }, [setIsNoPhotoAlertClosed]);

  const handleLevelStatusAlertClose = useCallback((level) => {
    setIsLevelStatusAlertVisible({...isLevelStatusAlertVisible, ...{[level]: false}});
    usersAPI.updateAlertStatus({id: `level${level}`, isManuallyClosed: true});
  }, [setIsLevelStatusAlertVisible]);

  if (!isEnabled && !isEnabledForProfile) {
    return null;
  }

  const getApiErrorMessage = (error: string) => {
    const id = `api_errors.${error}`;
    if (messages[id]) {
      return fm({
        id,
      });
    }
  };

  const renderPartnerName = (level: UserLevelStatus) => {
    switch (level) {
      case UserLevelStatus.White:
        return 'WHITE';
      case UserLevelStatus.Silver:
        return 'SILVER';
      case UserLevelStatus.Gold:
        return 'GOLD';
      case UserLevelStatus.Platinum:
        return 'PLATINUM';
      default:
        return '';

    }
  };

  return (
    <CurrentUser>
      {({user}) => {
        if (!user) {
          return null;
        }

        const userBonuses = user.roleId === RoleId.Realtor ? newRieltorBonuses : (user.roleId === RoleId.Owner ? newOwnerBonuses : null);
        // const newBonusStandardDate = user.roleId === RoleId.Realtor ? newRieltorBonusStandardDate : (user.roleId === RoleId.Owner ? newOwnerBonusStandardDate : '');

        const {active: newUserBonusActive, bonus: newUserBonusCoins, isNewUser: showStandardAlert} = !userBonuses ? {active: false, bonus: 0, isNewUser: false} : getNewUserBonus(userBonuses, user.tariffKey, user.createdAt);

        const newBonusAlert = (NEW_USER_BONUS_ALERTS_KO[user.roleId] || [])[0];
        const newBonusAlertOldUsers = (NEW_USER_BONUS_ALERTS_KO[user.roleId] || [])[1];

        const newBonusAlertId = showStandardAlert ? newBonusAlert : newBonusAlertOldUsers;

        const isRieltorManifestAlertEnabled = false;
        // For Agencies, if at least one rieltor has "low coins" alert
        const hasRieltorWithLowCoinsReserve = (user.rieltorsWithLowCoins ?? 0);

        return (
          <Box flexDirection="column">
            <ForceVerificationAlert/>

            {isRieltorManifestAlertEnabled && user.roleId === RoleId.Realtor && isUserAlertsEnabled && <RieltorManifest alertMode={true} visibleAlert={
              !(
                  (isUserAlertVisible && isUserAlertVisible[ALERT_RIELTOR_MANIFEST] === false) ||
                  !isUserAlertVisible || !isAlertStatusVisible(user, ALERT_RIELTOR_MANIFEST, usersAPI)
              )} alertCloseHandler={() => handleUserAlertClose(ALERT_RIELTOR_MANIFEST)}/>}
            {user.roleId === RoleId.AgencyAdmin &&
              !user.avatar &&
              user.userExpenses > 0 &&
              !isNoPhotoAlertClosed && (
                <Alert
                  className={classes.alert}
                  severity="info"
                  onClose={handleNoPhotoAlertClose}
                >
                  <span>
                    <FM
                      id="alerts.avatar.no_avatar.agency_text"
                      values={{
                        edit_link: (
                          <TrackableMuiLink
                            // @ts-ignore
                            to="/profile/edit"
                            component={RouterLink}
                            gaEvent={{
                              category: 'alerts',
                              action: 'agency_no_avatar',
                              label: 'edit_link',
                            }}
                            color="inherit"
                          >
                            <span className={classes.highlighted}>
                              <FM id="alerts.avatar.no_avatar.add_agency_logo" />
                            </span>
                          </TrackableMuiLink>
                        ),
                      }}
                    />
                  </span>
                </Alert>
              )}

            {user.roleId === RoleId.Realtor &&
              !user.avatar &&
              !isNoPhotoAlertClosed && (
                <Alert
                  className={classes.alert}
                  severity="info"
                  onClose={handleNoPhotoAlertClose}
                >
                  <span>
                    <FM
                      id="alerts.avatar.no_avatar.realtor_text"
                      values={{
                        edit_link: (
                          <TrackableMuiLink
                            // @ts-ignore
                            to="/profile/edit"
                            component={RouterLink}
                            gaEvent={{
                              category: 'alerts',
                              action: 'realtor_no_avatar',
                              label: 'edit_link',
                            }}
                            color="inherit"
                          >
                            <span className={classes.highlighted}>
                              <FM id="alerts.avatar.no_avatar.add_personal_photo" />
                            </span>
                          </TrackableMuiLink>
                        ),
                      }}
                    />
                  </span>
                </Alert>
              )}

            {user.roleId === RoleId.Realtor &&
                !user.verificated &&
                isNoVerifiedShowed && (
                    <Alert
                        className={cx(classes.alert, classes.nonVerifiedAlert)}
                        severity="info"
                        // onClose={handleNoVerifiedAlertClose}
                    >
                  <span>
                    <FM
                        id="alerts.non_verified_rieltor.text"
                        values={{
                          link: (
                              <TrackableMuiLink
                                  // @ts-ignore
                                  component={RouterLink}
                                  to="/profile/summary"
                                  gaEvent={{
                                    category: 'offers',
                                    action: 'free_offers_summary_profile',
                                  }}
                                  color="primary"
                              >
                            <span className={classes.highlighted}>
                              <FM id="alerts.non_verified_rieltor.link" />
                            </span>
                              </TrackableMuiLink>
                          ),
                        }}
                    />
                  </span>
                    </Alert>
                )}

            {user.roleId === RoleId.Realtor &&
              user.avatar &&
              user.userExpenses > 0 &&
              user.isPersonOnAvatar === false && (
                <Alert className={classes.alert} severity="warning">
                  <span>
                    <FM
                      id="alerts.avatar.not_a_person.text"
                      values={{
                        edit_link: (
                          <TrackableMuiLink
                            // @ts-ignore
                            to="/profile/edit"
                            component={RouterLink}
                            gaEvent={{
                              category: 'alerts',
                              action: 'not_a_person',
                              label: 'edit_link',
                            }}
                            color="inherit"
                          >
                            <span className={classes.highlighted}>
                              <FM id="alerts.avatar.not_a_person.upload_personal_photo" />
                            </span>
                          </TrackableMuiLink>
                        ),
                      }}
                    />
                  </span>
                </Alert>
              )}

            {
              hasRieltorWithLowCoinsReserve > 0 &&
              <Alert
                className={classes.alert}
                style={{alignItems: 'center'}}
                severity="warning"
              >
                <span>
                  <FM
                    id="alerts.multiple_rieltors_has_low_coins_reserve"
                    values={{
                      number: hasRieltorWithLowCoinsReserve,
                      link:
                        <MuiLink
                          to={createProfileAgentsManagementUrl({sort: 'balanceDaysLeft'})}
                          component={RouterLink}
                          color="inherit"
                        >
                          <b>Перейти до списку</b>
                        </MuiLink>,
                    }}
                  />
                </span>
              </Alert>
            }

            {isEnabledForManagement &&
              params[QueryParam.AlertType] === AlertType.EmailConfirmed && (
                <Alert className={classes.alert} severity="success">
                  <FM id="alerts.email_confirmed" />
                </Alert>
              )}
            {params[QueryParam.AlertType] === AlertType.ApiError &&
              params[QueryParam.AlertPayload] && (
                <Alert className={classes.alert} severity="warning">
                  {getApiErrorMessage(params[QueryParam.AlertPayload])}
                </Alert>
              )}

            {isEnabledForManagement &&
                (params[QueryParam.AlertType] === AlertType.PaymentSuccessful || params[QueryParam.AlertType] === AlertType.PaymentSuccessfulPrro) && (
                <Alert className={classes.alert} severity="success">
                  <span>
                    <FM
                      id={params[QueryParam.AlertType] === AlertType.PaymentSuccessful ? "alerts.payment.successful" : "alerts.payment.successful_prro"}
                      values={{
                        price: (
                          <Price
                            coins={
                              parseInt(params[QueryParam.AlertPayload], 10) || 0
                            }
                            CoinsProps={{
                              reverse: true,
                            }}
                          />
                        ),
                      }}
                    />
                  </span>
                </Alert>
              )}

            {isEnabledForManagement &&
                params[QueryParam.AlertType] === AlertType.PaymentGiftSuccessful && (
                    <Alert className={classes.alert} severity="success">
                  <span>
                    <FM
                        id="alerts.payment.payment_gift_successful"
                        values={{
                          username: params[QueryParam.AlertPayload2] || '',
                          price: (
                              <Price
                                  coins={
                                      parseInt(params[QueryParam.AlertPayload], 10) || 0
                                  }
                                  CoinsProps={{
                                    reverse: true,
                                  }}
                              />
                          ),
                        }}
                    />
                  </span>
                    </Alert>
                )}

            {isEnabledForManagement &&
              params[QueryParam.AlertType] === AlertType.PaymentProcessing && (
                <Alert className={classes.alert} severity="info">
                  <FM id="alerts.payment.processing" />
                </Alert>
              )}

            {isEnabledForManagement &&
              params[QueryParam.AlertType] === AlertType.PaymentWaitForCash && (
                <Alert className={classes.alert} severity="info">
                  <FM id="alerts.payment.wait_for_cash" />
                </Alert>
              )}

            {isEnabledForManagement &&
              params[QueryParam.AlertType] === AlertType.PaymentWaitCommon && (
                <Alert className={classes.alert} severity="info">
                  <FM id="alerts.payment.wait_common" />
                </Alert>
              )}

            {isEnabledForManagement &&
              params[QueryParam.AlertType] === AlertType.PaymentFailed && (
                <Alert className={classes.alert} severity="error">
                  <FM id="alerts.payment.failed" />
                </Alert>
              )}
            {params[QueryParam.AlertType] ===
              AlertType.BankidAlreadyVerifiedAnotherUser && (
              <Alert className={classes.alert} severity="error">
                <FM id="alerts.bankid.already_verified_another_user" />
              </Alert>
            )}
            {params[QueryParam.AlertType] === AlertType.ReferralsRegisterOnly && (
                <Alert className={classes.alert} severity="error">
                  <FM id="alerts.referrals_only_for_registration" />
                </Alert>
              )}
            {params[QueryParam.AlertType] ===
              AlertType.BankidAlreadyVerifiedAnotherUserLimit && (
              <Alert className={classes.alert} severity="error">
                <FM id="alerts.bankid.bankid_already_verified_another_user_limit" />
              </Alert>
            )}
            {params[QueryParam.AlertType] === AlertType.VerificationNotMatch && (
              <Alert className={classes.alert} severity="error">
                <FM id="alerts.bankid.verification_not_match" />
              </Alert>
            )}
            {params[QueryParam.AlertType] ===
              AlertType.BankidAlreadyVerified && (
              <Alert className={classes.alert} severity="error">
                <FM id="alerts.bankid.bankid_already_verified" />
              </Alert>
            )}
            {params[QueryParam.AlertType] === AlertType.BankidGeneralError && (
              <Alert className={classes.alert} severity="error">
                {params[QueryParam.AlertPayload]}
              </Alert>
            )}
            {params[QueryParam.AlertType] === AlertType.BankidErrorRedirect && (
              <Alert className={classes.alert} severity="error">
                <FM id="alerts.bankid.bankid_error_redirect" />
              </Alert>
            )}
            {params[QueryParam.AlertType] ===
              AlertType.BankidVerifiedSuccess && (
              <Alert className={classes.alert} severity="info">
                <FM id="alerts.bankid.bankid_verified_success" />
              </Alert>
            )}
            {params[QueryParam.AlertType] ===
              AlertType.BankidVerifiedSuccessNewRieltor && (
              <Alert className={classes.alert + ' -pre-wrap'} severity="info">
                <FM id={params[QueryParam.AlertPayload2] ? 'alerts.bankid.bankid_verified_new_rieltor_success_with_limit' : 'alerts.bankid.bankid_verified_new_rieltor_success'} values={{coins: params[QueryParam.AlertPayload], limit: params[QueryParam.AlertPayload2]}}/>
              </Alert>
            )}
            {params[QueryParam.AlertType] ===
              AlertType.DiiaVerifiedSuccess && (
              <Alert className={classes.alert} severity="info">
                <FM id="alerts.bankid.bankid_verified_success" />
              </Alert>
            )}
            {params[QueryParam.AlertType] ===
              AlertType.DiiaVerifiedSuccessNewRieltor && (
              <Alert className={classes.alert + ' -pre-wrap'} severity="info">
                <FM id={params[QueryParam.AlertPayload2] ? 'alerts.bankid.bankid_verified_new_rieltor_success_with_limit' : 'alerts.bankid.bankid_verified_new_rieltor_success'} values={{coins: params[QueryParam.AlertPayload], limit: params[QueryParam.AlertPayload2]}}/>
              </Alert>
            )}
            {params[QueryParam.AlertType] === AlertType.BankidUnknownError && (
              <Alert className={classes.alert} severity="error">
                <FM id="alerts.bankid.bankid_unknown_error" />
              </Alert>
            )}
            {params[QueryParam.AlertType] === AlertType.DiiaVerifiedAlreadyUsed && (
              <Alert className={classes.alert} severity="error">
                <FM id="diia.already_verified_another_user" />
              </Alert>
            )}
            {params[QueryParam.AlertType] === AlertType.DiiaVerifiedUserDeleted && (
              <DiiaAlert requestId={params[QueryParam.AlertPayload]} alertType={params[QueryParam.AlertType]}/>
            )}
            {params[QueryParam.AlertType] === AlertType.BankidAlreadyVerifiedUserDeleted && (
              <BankidAlert requestId={params[QueryParam.AlertPayload]} alertType={params[QueryParam.AlertType]}/>
            )}

            <VerificationSuccessAlert params={params} user={user}/>

            {(isEnabledForManagement) &&
              user.coinsReserve !== null &&
              user.coinsReserve <= INSUFFICIENT_FUNDS_ALERT_DAYS_MAX && (
                <Alert className={classes.alert} severity="warning">
                  <span>
                    <FM
                      id="alerts.insufficient_funds_alert"
                      values={{
                        daysCount: user.coinsReserve,
                        expires: (
                          <span className={classes.highlighted}>
                            <FM id="alerts.insufficient_funds_alert_expires" />
                          </span>
                        ),
                        link: (
                          <TrackableMuiLink
                            // @ts-ignore
                            to="/payment"
                            component={RouterLink}
                            gaEvent={{
                              category: 'alerts',
                              action: 'insufficient_funds',
                              label: 'payment_link',
                            }}
                            color="inherit"
                          >
                            <span className={classes.highlighted}>
                              <FM id="alerts.insufficient_funds_alert_pay" />
                            </span>
                          </TrackableMuiLink>
                        ),
                      }}
                    />
                  </span>
                </Alert>
              )}
            {!user.phones.length ? (
              <Alert className={classes.alert} severity="warning">
                <span>
                  <FM
                    id="alerts.no_phones"
                    values={{
                      link: (
                        <TrackableMuiLink
                          // @ts-ignore
                          to="/profile/edit"
                          component={RouterLink}
                          gaEvent={{
                            category: 'alerts',
                            action: 'no_phones',
                            label: 'edit_profile_link',
                          }}
                          color="inherit"
                        >
                          <span className={classes.highlighted}>
                            <FM id="alerts.user_edit" />
                          </span>
                        </TrackableMuiLink>
                      ),
                    }}
                  />
                </span>
              </Alert>
            ) : !user.phones.some((phone) => phone.isConfirmed) ? (
              <Alert className={classes.alert} severity="warning">
                <span>
                  <FM
                    id="alerts.no_confirmed_phone"
                    values={{
                      link: (
                        <TrackableMuiLink
                          // @ts-ignore
                          to="/profile/edit"
                          component={RouterLink}
                          gaEvent={{
                            category: 'alerts',
                            action: 'no_confirmed_phone',
                            label: 'edit_profile_link',
                          }}
                          color="inherit"
                        >
                          <span className={classes.highlighted}>
                            <FM id="alerts.user_edit" />
                          </span>
                        </TrackableMuiLink>
                      ),
                    }}
                  />
                </span>
              </Alert>
            ) : null}

            {isUserAlertsEnabled && user.alerts
              ? user.alerts.map(
                  (m: UserCustomAlert) =>
                    !(
                      (isUserAlertVisible && isUserAlertVisible[m.id] === false) ||
                      !isUserAlertVisible || !isAlertStatusVisible(user, m.id, usersAPI)
                    ) && (
                        <>
                          {m.alertType == 'gift-alert' && <RieltorGiftAlert
                            user={user} handleUserAlertClose={handleUserAlertClose}
                            alertId={m.id}
                            text={m.text}
                            showedAlerts={isUserAlertVisible}
                            isAlertStatusVisible={(id: string) => isAlertStatusVisible(user, id, usersAPI)}/>}
                          {m.alertType != 'gift-alert' && <Alert
                            icon={false}
                            key={'alert-' + m.id}
                            className={cx(classes.userAlert, {[classes.userAlertWarning]: m.type === 'warning'})}
                            severity={m.type || 'info'}
                            onClose={() => handleUserAlertClose(m.id)}
                            style={m.gradient ? {background: m.gradient} : {backgroundColor: m.color}}
                          >
                            <div
                              className={cx(classes.userAlertText)}
                              dangerouslySetInnerHTML={{__html: m.text}}
                            />
                          </Alert>}
                        </>
                    )
                )
              : null}
            {newUserBonusActive && !!user.hasNewRieltorBonus &&
                newBonusAlertId !== ALERT_NEW_RIELTOR_BONUS_OLD_USERS_KO &&
                newBonusAlertId !== ALERT_NEW_RIELTOR_BONUS_OLD_USERS_TOP &&
                !(
              (isUserAlertVisible && isUserAlertVisible[newBonusAlertId] === false) ||
              !isUserAlertVisible || !isAlertStatusVisible(user, newBonusAlertId, usersAPI)
            ) && <Box
                className={classes.alert}
            >
                <Box className={classes.alertPartner  + ' -new-user-bonus-alert'}>
                    <Box className={classes.alertPartnerClose} onClick={() => handleUserAlertClose(newBonusAlertId)}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M16 1.61143L14.3886 0L8 6.38857L1.61143 0L0 1.61143L6.38857 8L0 14.3886L1.61143 16L8 9.61143L14.3886 16L16 14.3886L9.61143 8L16 1.61143Z" fill="white"/>
                        </svg>
                    </Box>
                    <Box className={classes.alertPartnerInfo}>
                        <Box className={classes.alertPartnerTitle}><FM id={'alerts.new_rieltor_bonus_alert.title'} values={{username: user.firstName}}/></Box>
                      {showStandardAlert ?
                          <Box className={classes.alertPartnerText + ' -pre-wrap'}>{user.freeOfferLimit ? <FM id={'alerts.new_rieltor_bonus_alert.text_with_limit'} values={{limitText: <span>{user.freeOfferLimit}</span>, limit: user.freeOfferLimit, coins: <span>{newUserBonusCoins}&nbsp;грн</span>}}/> : <FM id={'alerts.new_rieltor_bonus_alert.text'} values={{coins: <span>{newUserBonusCoins}&nbsp;грн</span>}}/>}</Box> :
                        <Box className={classes.alertPartnerText + ' -pre-wrap'}><FM id={'alerts.new_rieltor_bonus_alert.old_new_text'} values={{coins: <span>{newUserBonusCoins}&nbsp;грн</span>}}/></Box>}
                    </Box>
                    <Box className={classes.alertPartnerImgWr}>
                      {newUserBonusCoins == 500 && user.freeOfferLimit == 5 ? <>
                        <img
                            className={classes.alertPartnerImg}
                            src={require(`./images/500_5.png`)}
                            style={{width: '300px', height: '238px'}}
                            alt=""
                            srcSet={`${require(`./images/500_5.png`)} 1x, ${require(`./images/500_5.png`)} 2x`}
                        />
                        <img
                            className={classes.alertPartnerImgMob}
                            src={require(`./images/500_5.png`)}
                            alt=""
                            srcSet={`${require(`./images/500_5.png`)} 1x, ${require(`./images/500_5.png`)} 2x`}
                        />
                      </> : <>
                        <img
                            className={classes.alertPartnerImg}
                            src={require(newUserBonusCoins == 500 ?
                                `./images/alert_new_rieltor_bonus_500_x1.png` :
                                `./images/alert_new_rieltor_bonus_x1.png`)}
                            alt=""
                            srcSet={`${require(newUserBonusCoins == 500 ?
                                `./images/alert_new_rieltor_bonus_500_x1.png` :
                                `./images/alert_new_rieltor_bonus_x1.png`)} 1x, ${require(
                                newUserBonusCoins == 500 ?
                                    `./images/alert_new_rieltor_bonus_500_x2.png` :
                                    `./images/alert_new_rieltor_bonus_x2.png`)} 2x`}
                        />
                        <img
                            className={classes.alertPartnerImgMob}
                            src={require(newUserBonusCoins == 500 ?
                                `./images/alert_new_rieltor_bonus_500_x1.png` :
                                `./images/alert_new_rieltor_bonus_mob_x1.png`)}
                            alt=""
                            srcSet={`${require(newUserBonusCoins == 500 ?
                                `./images/alert_new_rieltor_bonus_500_x1.png` :
                                `./images/alert_new_rieltor_bonus_mob_x1.png`)} 1x, ${require(
                                newUserBonusCoins == 500 ?
                                    `./images/alert_new_rieltor_bonus_500_x1.png` :
                                    `./images/alert_new_rieltor_bonus_mob_x1.png`)} 2x`}
                      /></>}
                    </Box>
                </Box>

            </Box>}

            {!!user.levelStatus && isUserAlertsEnabled && !(
              (isLevelStatusAlertVisible && isLevelStatusAlertVisible[user.levelStatus] === false) ||
              !isLevelStatusAlertVisible || !isAlertStatusVisible(user, `level${user.levelStatus}`, usersAPI)
            ) && <Box
                className={classes.alert}
            >

                <Box className={classes.alertPartner}>
                    <Box className={classes.alertPartnerClose} onClick={() => handleLevelStatusAlertClose(user.levelStatus)}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M16 1.61143L14.3886 0L8 6.38857L1.61143 0L0 1.61143L6.38857 8L0 14.3886L1.61143 16L8 9.61143L14.3886 16L16 14.3886L9.61143 8L16 1.61143Z" fill="white"/>
                        </svg>
                    </Box>
                    <Box className={classes.alertPartnerInfo}>
                        <Box className={classes.alertPartnerTitle}>Ви — {renderPartnerName(user.levelStatus)} partner</Box>
                        <Box className={classes.alertPartnerSubTitle}>Вітаємо, {user.firstName}!</Box>
                        <Box className={classes.alertPartnerText} dangerouslySetInnerHTML={{__html: user.levelStatusAlert}}/>

                        <ButtonWhite
                          // @ts-ignore
                            component={RouterLink}
                            to="/payment"
                            size="large"
                            className={classes.alertPartnerLink}
                        >
                            Поповнити рахунок
                            <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
                                <path d="M5.78419 5.30004L1.25829 0.774289C0.970386 0.486248 0.503602 0.486248 0.215839 0.774289C-0.0719464 1.06207 -0.0719464 1.52883 0.215839 1.8166L4.22057 5.8212L0.215956 9.82566C-0.07183 10.1136 -0.07183 10.5803 0.215956 10.8681C0.503741 11.156 0.970502 11.156 1.2584 10.8681L5.78431 6.34223C5.9282 6.19827 6.00007 6.00979 6.00007 5.82122C6.00007 5.63256 5.92806 5.44394 5.78419 5.30004Z" fill="white"/>
                            </svg>
                        </ButtonWhite>
                    </Box>
                    <Box className={classes.alertPartnerImgWr}>
                        <img
                            className={classes.alertPartnerImg}
                            src={require(`./images/alert_partner_${renderPartnerName(user.levelStatus).toLowerCase()}_x1_new.png`)}
                            alt=""
                            srcSet={`${require(`./images/alert_partner_${renderPartnerName(user.levelStatus).toLowerCase()}_x1_new.png`)} 1x, ${require(`./images/alert_partner_${renderPartnerName(user.levelStatus).toLowerCase()}_x2_new.png`)} 2x`}
                        />
                        <img
                            className={classes.alertPartnerImgMob}
                            src={require(`./images/mob_alert_partner_${renderPartnerName(user.levelStatus).toLowerCase()}_x1.png`)}
                            alt=""
                            srcSet={`${require(`./images/mob_alert_partner_${renderPartnerName(user.levelStatus).toLowerCase()}_x1.png`)} 1x, ${require(`./images/mob_alert_partner_${renderPartnerName(user.levelStatus).toLowerCase()}_x2.png`)} 2x`}
                        />
                    </Box>
                </Box>

            </Box>}
            {referralProgramRegisterEnabled && !isReferralBannerClosed &&
              isEnabled &&
              user.roleId === RoleId.Realtor && (
                <ReferralAlert user={user} onClose={() => handleCloseBanner()} />
              )}
          </Box>
        );
      }}
    </CurrentUser>
  );
};

const isAlertStatusVisible = (user: UserInfo, id: string, usersAPI: UsersAPI): boolean => {
  const alertStatus = user.alertStatusList[id];
  let isVisible = true;
  let isFirstView = false;

  if (alertStatus && alertStatus.hasOwnProperty('isManuallyClosed')) {
    isVisible = isVisible && !alertStatus.isManuallyClosed;
  }

  if (alertStatus && alertStatus.firstView) {
    const sevenDaysPassed = ((Date.now() / 1000 - alertStatus.firstView) / (60 * 60 * 24)) > 7;
    isVisible = isVisible && !sevenDaysPassed;
    isFirstView = true;
  }

  // System alerts ID starts with 1000000 or 2000000
  // @see User::getBanAlerts and Violations::getAlert
  const intId = parseInt(id, 10);
  const isSystemAlert = !isNaN(intId) && intId >= 1000000;

  if (isVisible && !isSystemAlert) {
    usersAPI.updateAlertStatus({id, firstView: isFirstView});
  }

  return isVisible;
};

const EnhancedUserAlerts = withRouter(UserAlerts);

export {EnhancedUserAlerts as UserAlerts};
