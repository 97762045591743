import {Pickup, UserAgenciesAutocompleteOption, UserAgencyOffice, UserAgencyOfficeList, UserAgencyOfficePost, UserInfo, AgentsManagementListing, AgentsListingSearch} from 'common/types';
import {apiMethod, APIService} from 'services/APIService';
import {createCRUDAPIService} from '../CRUDAPIService';
import {AvatarUploadInfo} from '../UsersAPI';
import {AgentsChipsCounters} from "../../screens/profile/ProfileNewAgentsScreen/config";

export interface FetchAgenciesParams {
  cityId: number;
  query: string;
}
export const UserAgencyOfficeCRUDAPI = createCRUDAPIService('/api/agency-offices');

type UploadProgressCallback = (progress: number) => void;

export interface UserAgencyOfficeUploadInfo {
  imageKey: string;
}

export class UserAgencyOfficesAPI extends UserAgencyOfficeCRUDAPI {
  //////////////////////// //////////////////
  fetchOffices = apiMethod<UserAgencyOfficeList[]>(() =>
    this.fetcher.request({
      url: '/api/agency-offices/',
      method: 'get',
    })
  );
  fetchAllOffices = apiMethod<UserAgencyOfficeList[]>(() =>
    this.fetcher.request({
      url: '/api/agency-offices/all/',
      method: 'get',
    })
  );
  uploadOffice = apiMethod<UserAgencyOfficeUploadInfo>(
    (file: File, onProgress?: UploadProgressCallback) => {
      const formData = new FormData();
      formData.append('file', file);

      return this.fetcher.request({
        url: '/api/agency-offices/upload-office/',
        method: 'post',
        data: formData,
        onUploadProgress: onProgress
          ? event => {
            const percentage = Math.floor((event.loaded * 100) / event.total);
            onProgress(percentage);
          }
          : undefined,
      });
    }
  );
}

export interface MoveCoinsParams {
  userId: number;
  coins: number;
}

export interface ChangeAgentOfficeParams {
  userId: number;
  officeId: number;
}

export class UserAgenciesAPI extends APIService {
  getChipsCounters = apiMethod<AgentsChipsCounters>((params: Partial<AgentsListingSearch>) =>
    this.fetcher.request({
      method: 'GET',
      url: '/api/user-agency/chips-counters/',
      params,
    })
  );

  fetchAgencies = apiMethod<UserAgenciesAutocompleteOption[]>(
    (params: FetchAgenciesParams) =>
      this.fetcher.request({
        method: 'get',
        url: 'api/user-agency/',
        params,
      })
  );

  connectUserAgency = apiMethod((firmId: number) =>
    this.fetcher.request({
      method: 'put',
      url: `/api/user-agency/${firmId}/`,
    })
  );

  deleteUserAgency = apiMethod((firmId: number) =>
    this.fetcher.request({
      method: 'delete',
      url: `/api/user-agency/${firmId}/`,
    })
  );

  fetchLinkedUsers = apiMethod<{data: AgentsManagementListing}>((params: Partial<AgentsListingSearch>) =>
    this.fetcher.request({
      url: '/api/linked-users/section-list/',
      method: 'get',
      params,
    })
  );

  approveLinkedUser = apiMethod((userId: number) =>
    this.fetcher.request({
      method: 'put',
      url: `/api/linked-users/${userId}/`,
    })
  );

  deleteLinkedUser = apiMethod((userId: number) =>
    this.fetcher.request({
      method: 'delete',
      url: `/api/linked-users/${userId}/`,
    })
  );

  moveCoins = apiMethod((data: MoveCoinsParams) =>
    this.fetcher.request({
      method: 'post',
      url: `/api/linked-users/move-coins/`,
      data
    })
  );
  changeAgentOffice = apiMethod((data: ChangeAgentOfficeParams) =>
    this.fetcher.request({
      method: 'post',
      url: `/api/linked-users/change-agent-office/`,
      data
    })
  );

  exportAgentsList = (params: Partial<AgentsListingSearch>) =>
    this.fetcher.request({
      method: 'get',
      url: '/api/linked-users/export-csv/',
      params,
    })
}
