import {useLocalStorage} from '@rehooks/local-storage';
import {
  COINS_TO_CURRENCY_EXCHANGE_RATE,
  LocalStorageKeys,
  QueryParam,
  UIPriceCurrency,
} from 'common/types';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {ExtendedUserInfo} from 'screens/common/CurrentUser';
import {AppState} from 'store';
import {parseLocationSearch} from '../utils/navigation';

export const useCurrentUser = (): null | ExtendedUserInfo => {
  const user = useSelector((state: AppState) => state.user.info.data);
  // const [uiPriceCurrency] = useLocalStorage(LocalStorageKeys.UIPriceCurrency);
  const uiPriceCurrency = UIPriceCurrency.Coins;
  if (user === null) {
    return null;
  }

  // tslint:disable-next-line:no-object-literal-type-assertion
  return {
    ...user,
    uiPriceCurrency: (Object.values(UIPriceCurrency) as any).includes(
      uiPriceCurrency
    )
      ? uiPriceCurrency
      : UIPriceCurrency.Coins
  } as ExtendedUserInfo;
};

// Simplified currency calculator
export const coinsInCurrency = (coins: number, currency: UIPriceCurrency) => {
  const exchangeRate = COINS_TO_CURRENCY_EXCHANGE_RATE[currency];
  const value = coins * exchangeRate;

  return new Intl.NumberFormat('uk-UA', {style: 'currency', currency: 'UAH'}).format(value);
};

// Check if url has specified param
export const useMarketingQueryParams = (param: QueryParam) => {
  const location = useLocation();
  const queryParams = useMemo(() => parseLocationSearch(location.search), [
    location,
  ]);

  return queryParams[param];
};
