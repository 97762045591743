import {Box, makeStyles} from '@material-ui/core';
import {BoxProps} from '@material-ui/core/Box/Box';
import cx from 'classnames';
import React from 'react';
import {rieltor} from 'styles/theme';

interface TypoProps extends BoxProps {
  variant: 'title0' | 'title1' | 'title2' |
    'text1bold' | 'text1normal' | 'text2' | 'text3' | 'text4';
}

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: rieltor.fontFamily,
    fontStyle: 'normal',
    display: 'inline-block',
  },
  title0: {
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: '30px',
  },
  title1: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  title2: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  text1bold: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  text1normal: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  text2: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  text3: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  text4: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
}));

const Typo = (
  {variant, children, className, ...rest}: TypoProps
) => {
  const classes = useStyles();

  return (
    <Box
      // @ts-ignore
      className={cx(classes.root, classes[variant], className)}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Typo;
