import {useMediaQuery, useTheme} from '@material-ui/core';
import {useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {APIResponseStatus, UserType} from '../../../common/types';
import {
  RegistrationAPI,
} from '../../../services/RegistrationAPI';
import {UsersAPI} from '../../../services/UsersAPI';
import {
  createRegistrationLink,
  extractAuxiliaryQueryParams,
  parseLocationSearch
} from '../../../utils/navigation';
import {registrationConfig} from '../config';

export const useIsMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('xs'));
};

export const useQueryParams = () => {
  const location = useLocation();

  return useMemo(
    () => parseLocationSearch(location.search),
    [location]
  );
};

export const useRedirectLink = () => {
  const queryParams = useQueryParams();

  const auxiliaryQueryParams = useMemo(
    () => extractAuxiliaryQueryParams(queryParams),
    [queryParams]
  );

  return createRegistrationLink(auxiliaryQueryParams);
};

export const useTempRegistration = (payload: any) => {
  const registrationAPI = new RegistrationAPI();

  registrationAPI.saveTempRegistration(payload);
};

export const getUserTypeConfig = (userType: UserType) => {
  if (userType === UserType.Owner) {
    return registrationConfig.filter(step => ['mainInfo', 'photo', 'verification'].includes(step.id));
  }

  if (userType === UserType.Agency) {
    return registrationConfig.filter(step => ['mainInfo', 'photo', 'profInfo', 'social'].includes(step.id));
  }

  return registrationConfig;
};

export const getLocalStorageData = () => {
  try {
    return JSON.parse(localStorage.getItem('registration') ?? '{}');
  } catch {
    return {};
  }
};

export const updateLocalStorage = (values: any) => {
  const registrationState = getLocalStorageData();

  localStorage.setItem('registration', JSON.stringify({
    ...registrationState,
    ...values,
  }));
};

export const validateNextStep = (currentStepId: string, values: any, errors: any) => {
  if (Object.keys(values).length === 0) {
    return false;
  }

  if (currentStepId === 'mainInfo') {
    return Object.keys(errors).filter(key => {
      return ['username', 'city', 'password', 'street', 'email'].includes(key);
    }).length === 0;
  }

  if (currentStepId === 'photo') {
    const invalidAvatar = Object.keys(errors).filter(key => {
      return ['avatar'].includes(key);
    }).length === 0;

    return invalidAvatar;
  }

  if (currentStepId === 'profInfo') {
    return Object.keys(errors).filter(key => {
      return ['specializations', 'about'].includes(key);
    }).length === 0;
  }

  if (currentStepId === 'verification') {
    // Allow owners & rietors skip verification
    if ([UserType.Owner, UserType.Realtor].includes(values.userType)) {
      return true;
    }

    return false;
  }

  if (currentStepId === 'social') {
    return Object.keys(errors).filter(key => {
      return ['facebook', 'instagram', 'youtube', 'tiktok', 'website'].includes(key);
    }).length === 0;
  }

  return false;
};

export const validateStepServer = (step: string, values: object) => {
  const usersAPI = new UsersAPI();

  return usersAPI.validateStep(step, values)
    .catch(error => {
      console.log(error);
    });
};

export const useBankIdLink = () => {
  const usersAPI = new UsersAPI();
  const [bankIdLink, setBankIdLink] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response= await usersAPI.generateBankidAuthLink();
      if (response.status === APIResponseStatus.Ok) {
        setBankIdLink(response.data);
      } else {
        setBankIdLink(null);
      }
    };

    fetchData();
  }, []);

  return bankIdLink;
};

export const useDiiaLink = (isMobile: boolean) => {
  const usersAPI = new UsersAPI();
  const [diiaLink, setDiiaLink] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await usersAPI.generateDiiaAuthLink(isMobile ? 1 : 0);
      if (response.status === APIResponseStatus.Ok) {
        const {deepLink, requestId} = response.data;
        if (isMobile) {
          setDiiaLink(`https://diia.page.link?link=${deepLink}&apn=ua.gov.diia.app&isi=1489717872&ibi=ua.gov.diia.app`);
        } else {
          setDiiaLink(deepLink);
        }
      }
    };

    fetchData();
  }, []);

  return diiaLink;
};
