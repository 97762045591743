import React from 'react';
import {FormattedMessage} from 'react-intl';
import {UserType} from '../../common/types';
import {OutlinedTab} from '../../components/OutlinedTabs';

export const TABS: OutlinedTab[] = [
  {
    value: UserType.Realtor,
    label: (
      <FormattedMessage id="registration_info_screen.form.user_type.realtor"/>
    ),
  },
  {
    value: UserType.Agency,
    label: (
      <FormattedMessage id="registration_info_screen.form.user_type.agency"/>
    ),
  },
  {
    value: UserType.Owner,
    label: (
      <FormattedMessage id="registration_info_screen.form.user_type.owner"/>
    ),
  },
];

export interface RegistrationStepConfig {
  id: string;
  title: string;
  subtitle?: string;
}

export const registrationConfig = [
  {
    id: 'mainInfo',
    title: 'Введіть основну інформацію',
  },
  {
    id: 'photo',
    title: 'Завантажте фото',
  },
  {
    id: 'profInfo',
    title: 'Заповніть професійну інформацію',
  },
  {
    id: 'social',
    title: 'Додайте соціальні мережі',
    subtitle: 'Додайте посилання на свої соціальні мережі, щоб клієнти могли підписатися на вас.',
  },
  {
    id: 'verification',
    title: 'Пройдіть верифікацію',
  },
];
