import useLocalStorage from '@rehooks/local-storage';
import {
  LocalStorageKeys,
  Nullable,
  UIPriceCurrency,
  UserInfo,
} from 'common/types';
import {FunctionComponent, useMemo} from 'react';
import {connect} from 'react-redux';
import {AppState} from 'store';
import * as UserActions from 'store/user/actions';
import * as UserSelectors from 'store/user/selectors';

export interface ExtendedUserInfo extends UserInfo {
  uiPriceCurrency: UIPriceCurrency;
}

export interface CurrentUserRenderProps {
  user: Nullable<ExtendedUserInfo>;
  fetchUserInfo: () => void;
  deleteProfile: () => void;
  logout: () => void;
  isFetching: boolean;
  setUIPriceCurrency: (currency: UIPriceCurrency) => void;
}

const mapStateToProps = (state: AppState) => ({
  user: UserSelectors.userInfo(state),
  isFetching: UserSelectors.isUserInfoFetching(state),
});

export interface CurrentUserProps extends ReturnType<typeof mapStateToProps> {
  children: (props: CurrentUserRenderProps) => any;
  fetchUserInfo: (
    ...args: Parameters<typeof UserActions.fetchUserInfo>
  ) => void;

  deleteProfile: (
    ...args: Parameters<typeof UserActions.deleteProfile>
  ) => void;
  logout: (...args: Parameters<typeof UserActions.logout>) => void;
}

const CurrentUser: FunctionComponent<CurrentUserProps> = ({
  user,
  children: render,
  fetchUserInfo,
  isFetching,
  deleteProfile,
  logout,
}: CurrentUserProps) => {
  const [uiPriceCurrency1, setUIPriceCurrency] = useLocalStorage(
    LocalStorageKeys.UIPriceCurrency
  );

  const uiPriceCurrency = UIPriceCurrency.Coins;

  const extendedUser = useMemo(() => {
    if (!user) {
      return null;
    }

    return {
      ...user,
      uiPriceCurrency: (Object.values(UIPriceCurrency) as any).includes(
        uiPriceCurrency
      )
        ? uiPriceCurrency
        : UIPriceCurrency.Coins,
    };
  }, [user, uiPriceCurrency]);

  return render({
    user: extendedUser as ExtendedUserInfo,
    fetchUserInfo,
    isFetching,
    deleteProfile,
    logout,
    setUIPriceCurrency,
  });
};

const ConnectedCurrentUser = connect(mapStateToProps, {
  fetchUserInfo: UserActions.fetchUserInfo,
  deleteProfile: UserActions.deleteProfile,
  logout: UserActions.logout,
})(CurrentUser);

export {ConnectedCurrentUser as CurrentUser};
