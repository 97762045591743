import {
  FETCH_GLOBAL_CONFIG_FAILURE,
  FETCH_GLOBAL_CONFIG_REQUEST,
  FETCH_GLOBAL_CONFIG_SUCCESS,
  GlobalConfigAction
} from "./types";
import {GlobalConfig, Nullable} from "../../common/types";

interface GlobalConfigState {
  isFetching: boolean;
  isSuccess: boolean;
  error: Nullable<Error>;
  data?: GlobalConfig;
}

const initialState: GlobalConfigState = {
  isFetching: false,
  isSuccess: false,
  error: null,
  data: {
    vatPercent: 0,
    showVatAlert: false,
    supportHours: {},
    avatar: {
      maxSize: 0,
      rieltor: {
        allowedMimeExtensions: [],
        allowedExtensions: [],
      },
      owner: {
        allowedMimeExtensions: [],
        allowedExtensions: [],
      },
      agency: {
        allowedMimeExtensions: [],
        allowedExtensions: [],
      }
    },
    offers: {
      imageUpload: {
        maxSize: 0,
        allowedMimeExtensions: [],
        allowedExtensions: [],
        minResolution: '800x600'
      },
    },
    referralProgram: {
      payments: false,
      active: false,
      register: false,
      registerBonus: 0,
      paymentBonus: 0,
    }
  },
};

export const globalConfigReducer = (state: GlobalConfigState = initialState, action: GlobalConfigAction) => {
  switch (action.type) {
    case FETCH_GLOBAL_CONFIG_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
        isActual: false,
      };

    case FETCH_GLOBAL_CONFIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.globalConfig,
        isActual: true,
      };

    case FETCH_GLOBAL_CONFIG_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,

        isActual: false,
      };
    default:
      return state;
  }
}