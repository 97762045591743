import {makeStyles} from '@material-ui/core';
import React from 'react';
import {rieltor} from 'styles/theme';
import {useSubscription} from '../../hooks/subscription';

interface ProLabelProps {
  variant: 'small' | 'big';
}

const useProLabelStyles = makeStyles(() => ({
  proLabel: {
    backgroundColor: rieltor.yellow,
    color: '#000',
    '&.big': {
      width: '42px',
      height: '26px',
      borderRadius: '6px',
    },
    '&.small': {
      width: '24px',
      height: '15px',
      borderRadius: '3px',
    },
  },
}));

const ProLabel = (
  {variant}: ProLabelProps,
) => {
  const [subscription, isSubsActive] = useSubscription();

  if (!subscription || !isSubsActive) {
    return null;
  }

  return (
    <ProIcon variant={variant}/>
  );
};

export default ProLabel;

export const ProIcon = (
  {variant}: ProLabelProps
) => {
  const classes = useProLabelStyles();

  return (
    <svg
      width="42"
      height="26"
      viewBox="0 0 42 26"
      xmlns="http://www.w3.org/2000/svg"
      className={`${classes.proLabel} ${variant}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6613 8.99023C33.1583 8.99023 35.6773 10.0572 35.6773 12.9942C35.6773 15.9422 33.1583 17.0092 30.6613 17.0092C28.1643 17.0092 25.6562 15.9422 25.6562 12.9942C25.6562 10.0572 28.1643 8.99023 30.6613 8.99023ZM30.6613 15.2492C32.1793 15.2492 33.0263 14.4022 33.0263 12.9942C33.0263 11.5972 32.1793 10.7502 30.6613 10.7502C29.1543 10.7502 28.2962 11.5972 28.2962 12.9942C28.2962 14.4022 29.1543 15.2492 30.6613 15.2492ZM22.3009 16.8445L20.7389 14.3475H18.6929V16.8445H16.0859V9.14453H21.5639C23.6869 9.14453 24.8529 10.1235 24.8529 11.6965C24.8529 12.8625 24.2589 13.6105 23.2799 14.0065L25.1059 16.8445H22.3009ZM18.6929 10.9265V12.5875H21.1899C21.9599 12.5875 22.2019 12.1915 22.2019 11.6965C22.2019 11.1905 21.8829 10.9265 21.1899 10.9265H18.6929ZM14.9851 11.7515C14.9851 10.1785 13.9181 9.14453 11.7951 9.14453H6.32812V16.8445H8.93512V14.4465H11.4871C13.6101 14.4465 14.9851 13.6875 14.9851 11.7515ZM12.3341 11.7515C12.3341 12.3455 11.9931 12.6865 11.1791 12.6865H8.93512V10.9265H11.1791C11.9491 10.9265 12.3341 11.1465 12.3341 11.7515Z"
        fill="black"
      />
    </svg>
  );
};
