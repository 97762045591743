import {RoleId} from 'common/types';
import {
  Dashboard,
  Deals, ExternalUrl,
  MyWebsite,
  Offers,
  Partners,
  ProfileSettings,
  ProfileStats, Unions,
} from 'components/Icons';
import {Badge} from 'components/UserMenu/UserMenuItem';
import React from 'react';
type Callback = (props: any) => boolean;
export interface UserMenuConfigItem {
  uid: string;
  roles: RoleId[];
  visible?: Callback;
  url: string;
  label: string;
  icon: JSX.Element;
  iconEnd?: JSX.Element;
  new: boolean;
  badge?: (props: UserMenuBadge) => JSX.Element;
  isProFeature?: boolean | Callback; // FIXME Let's backend decide, if menu item (route) require PRO feature
}

export interface UserMenuBadge {
  activeOffersCount: number;
  partnersConfirmed?: number;
  partnersRequests?: number;
  agentsRequests?: number;
  referralCount?: number;
  referralEnabled?: boolean;
}

export const UserMenuConfig: UserMenuConfigItem[] = [
  {
    uid: 'dashboard',
    roles: [RoleId.Realtor, RoleId.AgencyAdmin],
    url: '/dashboard',
    label: 'user_profile_menu.dashboard',
    icon: <Dashboard/>,
    new: false,
  },
  {
    uid: 'offers_management',
    roles: [RoleId.Realtor, RoleId.AgencyAdmin, RoleId.Owner],
    url: '/offers/management',
    label: 'user_profile_menu.my_offers',
    icon: <Offers/>,
    new: false,
    badge: (props: UserMenuBadge) => {
      return <Badge value={props.activeOffersCount}/>;
    },
  },
  {
    uid: 'deals',
    roles: [RoleId.Realtor, RoleId.AgencyAdmin],
    url: '/deals',
    label: 'user_profile_menu.my_deals',
    icon: <Deals/>,
    new: false,
    isProFeature: ({user}: any) => {
      return user.roleId === RoleId.Realtor && user.verificated;
    },
  },
  {
    uid: 'partners',
    roles: [RoleId.Realtor],
    url: '/partners',
    label: 'user_profile_menu.partners',
    icon: <Partners/>,
    new: false,
    badge: (props: UserMenuBadge) => {
      if (props.partnersRequests && props.partnersRequests > 0) {
        return <Badge value={`+${props.partnersRequests}`}/>;
      }

      return <Badge value={props.partnersConfirmed}/>;
    },
  },
  {
    uid: 'unions',
    roles: [],
    visible: (props: any) => {
      if (!props.user) {
        return false;
      }
      return !!(props.user.asnu || props.user.sfnu);
    },
    url: '/unions',
    label: 'user_profile_menu.unions',
    icon: <Unions/>,
    new: true,
  },
  {
    uid: 'agents',
    roles: [RoleId.AgencyAdmin],
    url: '/profile/agents',
    label: 'user_profile_menu.agents',
    icon: <Partners/>,
    new: false,
    badge: (props: UserMenuBadge) => {
      if (props.agentsRequests && props.agentsRequests > 0) {
        return <Badge value={`+${props.agentsRequests}`}/>;
      }

      return <></>;
    },
  },
  // {
  //   uid: 'referral',
  //   roles: [RoleId.Realtor],
  //   url: '/referrals',
  //   label: 'user_profile_menu.referrals',
  //   icon: <Partners/>,
  //   new: true,
  //   badge: (props: UserMenuBadge) => {
  //     return <Badge value={props.referralCount}/>;
  //   },
  //   visible: (props: any) => {
  //     return !!props.referralEnabled;
  //   }
  // },

  // {
  //   uid: 'manifesto',
  //   roles: [RoleId.Realtor],
  //   url: '/rieltor-manifest',
  //   label: 'user_profile_menu.rieltor_manifest',
  //   icon: <Rieltor/>,
  //   new: false,
  // },
  {
    uid: 'profile',
    roles: [RoleId.Realtor, RoleId.AgencyAdmin, RoleId.Owner],
    url: '/profile/stats',
    label: 'user_profile_menu.stats',
    icon: <ProfileStats/>,
    new: false,
  },
  {
    uid: 'my_website',
    roles: [RoleId.Realtor, RoleId.AgencyAdmin],
    url: '',
    label: 'user_profile_menu.my_site',
    icon: <MyWebsite/>,
    new: false,
    iconEnd: <ExternalUrl/>
  },
  {
    uid: 'profile_summary',
    roles: [RoleId.Realtor, RoleId.AgencyAdmin, RoleId.Owner],
    url: '/profile/summary',
    label: 'user_profile_menu.profile',
    icon: <ProfileSettings/>,
    new: false,
  },
];
